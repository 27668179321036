<div fxLayout="column">
    <div fxLayout="row">
        <h1 mat-dialog-title fxFlex="90%">{{'document.upload-title' | translate}} </h1>


        <button mat-icon-button fxFlex (click)="close()" id="clear">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <br/>
    <div mat-dialog-content *ngIf="!endOfUploading">
        <files-upload #filesUpload [rootIdForUpload]="id" (uploadCompleted)="onUploadCompleted($event)" [documentId]="documentId"></files-upload>
    </div>
    <div mat-dialog-content *ngIf="endOfUploading">
        <p>{{ info }} <a (click)="viewFiles()" style="cursor: pointer; text-decoration: underline;"> {{ 'document.upload-result-link' | translate }}</a></p>
    </div>
</div>


