import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent, ConfirmationDialogData} from './confirmation-dialog.component';
import {Observable} from 'rxjs';

@Injectable()
export class ConfirmationDialogService {
    constructor(public dialog: MatDialog) {
    }

    public confirm(data: ConfirmationDialogData): Observable<boolean> {
        return this.dialog.open(ConfirmationDialogComponent, {
            width: '450px',
            data,
            panelClass: 'custom-dialog-container'
        }).afterClosed();
    }

    public confirmPromise(data: ConfirmationDialogData): Promise<null> {
        return new Promise((resolve, reject) => {
            this.dialog.open(ConfirmationDialogComponent, {
                width: '450px',
                data,
                panelClass: 'custom-dialog-container'
            }).afterClosed().subscribe(value => {
                if (value) {
                    resolve(null);
                    return;
                } else {
                    reject();
                    return;
                }
            });
        });

    }
}
