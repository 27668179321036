import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Output, QueryList, ViewChildren} from '@angular/core';
import {HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {FileSystemFileEntry, UploadEvent} from '../ngx-file-drop/ngx-drop';
import {DirectoryVdrService} from '../directory-vdr';
import {SERVER_API_URL} from '../../app.constants';
import {merge, Subscription} from 'rxjs';
import {DirectoryStructure, FileAndDir} from './directory-structure.component';
import {startWith} from 'rxjs/operators';
import {SingleFileUploadComponent} from './single-file-upload.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';

const delay = (ms: number) => {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
};

@Component({
    selector: 'files-upload',
    templateUrl: 'files-upload.component.html',
    styleUrls: ['upload.scss'],
})
export class FilesUploadComponent implements OnDestroy, AfterViewInit {

    structure: DirectoryStructure;

    files: Array<FileAndDir> = [];

    directories = false;

    uploadInProgress = false;

    structureCreated = false;

    uploadUrl = SERVER_API_URL + '/api/upload-file';

    @Input()
    rootIdForUpload: number;

    @Input()
    documentId: number;

    @Output()
    uploadCompleted = new EventEmitter<number>();

    @ViewChildren(forwardRef(() => SingleFileUploadComponent)) fileUploads: QueryList<SingleFileUploadComponent>;
    fileUploadsNotCompleted: SingleFileUploadComponent[] = [];
    fileUploadedComponents: SingleFileUploadComponent[] = [];
    totalSizeToUploadBytes: number;

    batchId: string;

    uploadedFilesSet = new Set();

    registeredFilesSet = new Set();

    private _changeSubscription: Subscription;

    private _uploadCompletedSubscription: Subscription | null;

    private _fileRemoveSubscription: Subscription | null;

    get uploadCompletedSubscription() {
        return merge(...this.fileUploads.map((fileUpload) => fileUpload.uploadFinished));
    }

    get fileUploadRemoveEvents() {
        return merge(...this.fileUploads.map((fileUpload) => fileUpload.removeEvent));
    }

    constructor(
        private directoryService: DirectoryVdrService,
        private spinner: NgxSpinnerService,
        private alertService: JhiAlertService
    ) {
        this.uploadCompleted = new EventEmitter<number>();
    }

    public async startUpload() {
        this.uploadInProgress = true;

        if (!this.batchId) {
            this.batchId = this.randomAlphaNumeric(20);
        }

        if (this.directories && !this.structureCreated) {
            const createdStructure = await this.createStructure();
            this.structureCreated = true;
            this.structure = this.mergeDirectoryStructure(this.structure, createdStructure);
        }

        this.files.forEach((element) => {
            if (!element.directoryId) {
                element.directoryId = this.rootIdForUpload;
            }
            element.batchId = this.batchId;
        });

        this.totalSizeToUploadBytes = this.fileUploads.reduce((sum, current) => sum + current.total, 0);

        await this.uploadFiles();

        }

    private async createStructure() {
        return this.directoryService.structure(this.structure).toPromise();
    }

    private async uploadFiles() {
        this.fileUploadsNotCompleted = this.fileUploads.filter((item) => !item.uploadCompleted).reverse();

        let fileToUpload = this.fileUploadsNotCompleted.pop();
        while (fileToUpload) {
            await this.uploadFile(fileToUpload);
            fileToUpload = this.fileUploadsNotCompleted.pop();
        }
    }

    private async uploadFile(fileToUpload: SingleFileUploadComponent) {
        let retries = 0;
        let completed = false;

        do {
            if (!this.uploadInProgress) {
                console.log('Upload aborted');
                return;
            }

            completed = await fileToUpload.upload().then((res) => {
                if (this.registeredFilesSet.has(res)) {
                    return true;
                }
                this.registeredFilesSet.add(res);
                this.fileUploadedComponents.push(fileToUpload);
                return true;
            }).catch(async(reason: HttpErrorResponse) => {
                console.log('Unable to upload file:' + reason.message);
                let delayTime = retries * 5000;

                if (reason.headers.has('x-rate-limit-retry-after-seconds')) {
                    delayTime = Number.parseInt(reason.headers.get('x-rate-limit-retry-after-seconds'), 10) * 1000;
                    delayTime += 1000; // add some more delay, just to be sure
                    console.log('rate limiter', delayTime);
                } else {
                    retries++;
                }


                await delay(delayTime);
                return false;
            });

            console.log(retries, completed);
        } while (retries <= 5 && !completed);
    }


    getTotalSize() {
        return this.getDisplayMB(this.totalSizeToUploadBytes);
    }


    getUploadedSize() {
        const sizeBytes = this.fileUploadedComponents
            .reduce((sum, current) => sum + (current.progressPercentage / 100) * current.total, 0);
        return this.getDisplayMB(sizeBytes);
    }

    private getDisplayMB(bytes: number) {
        return Math.floor(bytes / 100000) / 10;
    }


    public removeAll() {
        this.files.splice(0, this.files.length);
        this.structure = null;
        this.batchId = null;
    }

    ngOnDestroy() {
        if (this.files) {
            this.removeAll();
        }
    }

    ngAfterViewInit(): void {
        this.subscribeToChanges();
    }

    subscribeToChanges() {
        this._changeSubscription = this.fileUploads.changes.pipe(startWith(null)).subscribe(() => {
            if (this._fileRemoveSubscription) {
                this._fileRemoveSubscription.unsubscribe();
            }
            this._listenTofileRemoved();
            this.listenToUploadSuccess();
        });
    }

    private listenToUploadSuccess(): void {
        this._uploadCompletedSubscription = this.uploadCompletedSubscription.subscribe((value) => {
            if (value && this.uploadInProgress) {
                this.uploadedFilesSet.add(value);
                if (this.uploadedFilesSet.size === this.files.length) {
                    this.uploadInProgress = false;
                    this.alertService.info('document.upload-message');
                    this.uploadCompleted.emit(this.files.length);
                }
            }
        });
    }

    private _listenTofileRemoved(): void {
        this._fileRemoveSubscription = this.fileUploadRemoveEvents.subscribe((event: SingleFileUploadComponent) => {
            const index = this.files.indexOf(event.file);
            this.files.splice(index, 1);
            if (this.structure) {
                this.deleteFileFromStructure(event.file);
            }
            const files = this.fileUploads.toArray();
            const ind = files.indexOf(event);
            if (ind !== -1) {
                files.splice(ind, 1);
            }
            this.fileUploads.reset(files);
        });
    }

    public dropped(event: UploadEvent) {
        this.spinner.show();
        console.log(event);

        setTimeout(() => {
            this.structure = new DirectoryStructure();
            this.structure.name = 'New structure';
            this.structure.active = true;
            this.structure.children = {};
            this.structure.files = [];
            this.structure.id = this.rootIdForUpload;
            this.structure.level = 0;
            this.directories = false;
            for (const droppedFile of event.files) {
                if (!droppedFile.fileEntry.isFile) {
                    continue;
                }

                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    const fileAndDir = new FileAndDir();
                    fileAndDir.file = file;
                    this.files.push(fileAndDir);
                    const paths: string[] = droppedFile.relativePath.split('/');
                    let currentParent = this.structure;
                    if (paths.length > 1) {
                        this.directories = true;
                        paths.pop();
                         paths.forEach((value) => {
                            let currentDir = currentParent.children[value];
                            if (!currentDir) {
                                const newDir = new DirectoryStructure();
                                newDir.name = value;
                                newDir.children = {};
                                newDir.files = [];
                                newDir.id = null;
                                newDir.active = true;
                                newDir.level = currentParent.level + 1;
                                currentDir = newDir;
                                currentParent.children[value] = currentDir;
                            }
                            currentParent = currentDir;
                        });
                    }
                    currentParent.files.push(fileAndDir);
                });
            }
            this.spinner.hide();
        }, 100);
    }

    public filesOpen(event) {
        this.structure = null;
        this.directories = false;
        const filesSelected: File[] = Array.from(event.target.files);
        if (filesSelected) {
            filesSelected.forEach((file: File) => {
                const fileAndDir = new FileAndDir();
                fileAndDir.file = file;
                this.files.push(fileAndDir);
            });
        }

    }

    public deleteFileFromStructure(file: FileAndDir) {
        if (this.structure) {
            const dir = this.containsFileRecursive(this.structure, file);
            if (dir) {
                const index = dir.files.indexOf(file);
                dir.files.splice(index, 1);
                this.clearDirectory(dir);

            }
        }
    }

    clearDirectory(dir: DirectoryStructure) {
        if (dir.files.length === 0 && dir.childrenDisabled()) {
            dir.active = false;
            const parent = this.findParent(this.structure, dir);
            if (parent) {
                this.clearDirectory(parent);
            }
        }
    }

    findParent(structure: DirectoryStructure, dir: DirectoryStructure): DirectoryStructure {
        if (!structure) {
            return null;
        }
        if (structure.children.hasOwnProperty(dir.name)) {
            return structure;
        }
        for (const key in structure.children) {
            if (structure.children.hasOwnProperty(key)) {
                const directory = structure.children[key];
                const node = this.findParent(directory, dir);
                if (node) {
                    return node;
                }
            }
        }
    }

    containsFileRecursive(dir: DirectoryStructure, file: FileAndDir): DirectoryStructure {
        if (!dir) {
            return null;
        }
        if (dir.files.filter((value) =>
            value === file
        ).length === 1) {
            return dir;
        }
        for (const key in dir.children) {
            if (dir.children.hasOwnProperty(key)) {
                const directory = dir.children[key];
                const node = this.containsFileRecursive(directory, file);
                if (node) {
                    return node;
                }
            }
        }
    }

    mergeDirectoryStructure(structure: DirectoryStructure, structure2: DirectoryStructure): DirectoryStructure {
        if (structure.id === null) {
            structure.id = structure2.id;
        }
        if (structure.files && structure.files.length > 0) {
            structure.files.forEach((fileAndDir) => {
                fileAndDir.directoryId = structure.id;
                fileAndDir.batchId = structure2.batchId;
                const tmpFile = this.files.find((value) => value.file === fileAndDir.file);
                if (tmpFile) {
                    tmpFile.directoryId = structure.id;
                }
            });
        }
        const children: { [key: string]: DirectoryStructure } = {};
        if (structure.children) {
            for (const key in structure.children) {
                if (structure.children.hasOwnProperty(key) && structure2.children.hasOwnProperty(key)) {
                    const directory = structure.children[key];
                    children[key] = this.mergeDirectoryStructure(directory, structure2.children[key]);
                }
            }
        }
        structure.children = children;
        return structure;
    }

    onChildrenChange(event: QueryList<SingleFileUploadComponent>) {
        this.fileUploads.reset(event.toArray());
        this.subscribeToChanges();
    }

    randomAlphaNumeric(length: number) {
        let s = '';
        do {
            s += Math.random().toString(36).substr(2);
        } while (s.length < length);
        s = s.substr(0, length);
        return s;
    }

    stopUpload() {
        this.uploadInProgress = false;
    }

    isUploadInProgress() {
        return this.uploadInProgress;
    }
}


