<section fxLayout="row" fxLayoutAlign="start center" style="min-height: 40px !important" #section>
    <div fxLayout="column" fxFlex>
        <div fxLayout="row">
            <span class="file-info" ><span [innerHTML]="getIconTag(file.file.name)" style="margin-right: 5px"></span> {{file.file.name}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="95%" >
                <p-progressBar [value]="progressPercentage" unit="%" mode="determinate" [showValue]="true"></p-progressBar>
            </div>
            <div fxLayout="column" fxFlex>
                <a *ngIf="uploadError">
                    <mat-icon class="action" (click)="upload()">file_upload</mat-icon>
                </a>
                <mat-icon class="action" (click)="remove()" *ngIf="!uploadStarted">cancel</mat-icon>
            </div>
        </div>
    </div>
</section>
<!--<br/>-->


