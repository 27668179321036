import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FocusMonitor} from '@angular/cdk/a11y';
import {TranslateService} from '@ngx-translate/core';
import {FilesUploadComponent} from '@entities/file-upload/files-upload.component';
import {ConfirmationDialogService} from '@entities/confirmation-dialog/confirmation-dialog.service';

export interface UploadDialogData {
    directoryId: number;
    documentId?: number;
}

@Component({
    selector: 'upload-dialog',
    templateUrl: 'upload.component.html',
    styleUrls: ['directory-document.scss']
})
export class UploadDialogComponent implements AfterViewInit {

    id: number;
    documentId: number;
    endOfUploading = false;
    uploadedFileCount = 10;
    info = '';

    @ViewChild('filesUpload')
    filesUploadComponent: FilesUploadComponent;

    constructor(
        public dialogRef: MatDialogRef<UploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
        private _focusMonitor: FocusMonitor,
        private translateService: TranslateService,
        private confirmation: ConfirmationDialogService
    ) {
        this.id = this.data.directoryId;
        this.documentId = this.data.documentId;
        this.info = this.translateService.instant('document.upload-result-info', {'total': this.uploadedFileCount});
    }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('clear'));
        this.endOfUploading = false;
    }

    close(): void {
        const performClose = () => {
            this.dialogRef.close({viewFileProcessingState: false});
            this.filesUploadComponent.stopUpload();
        };

        if (!this.filesUploadComponent.isUploadInProgress()) {
            performClose();
            return;
        }

        const dialogData =
            {
                title: this.translateService.instant('global.dialog.areyousure'),
                text: this.translateService.instant('vdrApp.document.upload.closeConfirmText')
            };
        this.confirmation.confirmPromise(dialogData)
            .then(() => {
                performClose();
            });
    }

    onUploadCompleted(event) {
        this.uploadedFileCount = event;
        this.endOfUploading = true;
        this.info = this.translateService.instant('document.upload-result-info', {'total': this.uploadedFileCount});
    }

    viewFiles(): void {
        this.dialogRef.close({viewFileProcessingState: true});
    }

}

