import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {DocumentVdrService} from '../../document-vdr';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {getFileNameFromResponseContentDispositionBase64, saveFile, UploadDownloadService} from '../upload-download.service';
import {DirectoryDocumentRowDto} from '../dto/directory-document-row-dto.model';
import {DownloadObjectDto} from '../dto/download-object-dto.model';
import {NgxSpinnerService} from 'ngx-spinner';
import getIconForExtension from 'font-awesome-filetypes';
import {DirectoryDocumentViewSettingsDto} from '../dto/directory-document-view-settings-dto.model';
import {DirectoryDocumentService} from '../directory-document.service';
import {DocumentCriteriaDto} from '../dto/document-criteria-dto.model';
import {DocumentType} from '../dto/document-type.enum';
import {enumToKeyValue, Principal, UtilsService} from '../../../shared';
import {SearchIn} from '../dto/search-in.enum';
import {DocumentSearchComponent} from '../document-search.component';
import {PdfPasswordProtectionDialog} from '../pdf-password-protection-dialog/pdf-password-protection-dialog';
import {DocumentSpecialRowDto} from '../dto/document-special-row-dto.model';
import {PermanentDeleteDialog} from './permanent-delete-dialog';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenericProgressDialog} from '@shared/generic-progress-dialog/generic-progress-dialog.component';
import {DocumentEventHistoryDialog} from '@entities/directory-document/document-event-history/document-event-history-dialog';
import {ConfirmationDialogData} from '@entities/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from '@entities/confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';

export class SelectedItem {
    constructor(
        public id?: number,
        public type?: string,
    ) {
    }
}

export class DirectoryChange {
    constructor(
        public id?: number,
        public refreshId?: number,
    ) {
    }
}

@Component({
    selector: 'management-document-list',
    templateUrl: 'management-document-list.component.html',
    styleUrls: ['./../directory-document.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'}
    ]
})
export class ManagementDocumentListComponent implements AfterViewInit, OnChanges {
    _directoryId: number;
    @Input() directoryName: string;
    @Input() nodeType: string;
    @Input() ddvSettings: DirectoryDocumentViewSettingsDto;
    @Output() selectedDocuments = new EventEmitter<SelectedItem[]>();
    @Output() directoryAdded = new EventEmitter<DirectoryChange>();
    @Output() directorySelected = new EventEmitter<number>();
    displayedColumns: string[];
    dataSource = new MatTableDataSource<DocumentSpecialRowDto>();
    selection = new SelectionModel<DocumentSpecialRowDto>(true, []);
    copy = false;
    copyCutInProgress = false;
    infectedMode = false;
    documentCriteriaDto: DocumentCriteriaDto;
    documentTypesSelect: any;

    documentCriteriaDtoTmp: DocumentCriteriaDto;
    public SearchIn = SearchIn;

    @ViewChild('documentSearchComponent') documentSearchComponent: DocumentSearchComponent;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private documentService: DocumentVdrService,
        private jhiAlertService: JhiAlertService,
        public dialog: MatDialog,
        private uploadDownloadService: UploadDownloadService,
        private spinner: NgxSpinnerService,
        private directoryDocumentService: DirectoryDocumentService,
        private utilsService: UtilsService,
        private confirmation: ConfirmationDialogService,
        private translate: TranslateService,
        private principal: Principal
    ) {
        this.displayedColumns = ['select', 'favourite', 'type', 'number', 'name', 'newDocument', 'createdDate'];
        this.initDocumentCriteria();
        this.utilsService.initSvgIcons();
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    initDocumentCriteria() {
        this.documentCriteriaDto = new DocumentCriteriaDto();
        this.documentCriteriaDto.read = null;
        this.documentTypesSelect = enumToKeyValue(DocumentType);
    }

    areFilesSelected() {
        const numSelected = this.selection.selected.length;
        return numSelected > 0;
    }

    onSelection(row: DirectoryDocumentRowDto) {
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() :
            this.dataSource.data.forEach((row) => {
                this.selection.select(row);
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        let numRows = 0;
        this.dataSource.data.forEach((x) => {
            numRows++;
        });
        return numSelected === numRows;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        this.displayedColumns = ['select', 'id', 'type', 'name', 'fullPath', 'permission', 'createdDate'];
        // this.dataSource.sort = this.sort;
        for (const propName of Object.keys(changes)) {
            if (propName === 'directoryId') {
                if (this.directoryId) {
                    this.infectedMode = false;
                    this.loadAll();
                    this.selection.clear();
                }
            }
        }
    }

    getDownloadObjectFromSelection(): DownloadObjectDto[] {
        const ids: DownloadObjectDto[] = [];
        this.selection.selected.forEach((value) => {
            const obj = new DownloadObjectDto(value.id, (value.type === 'directory') ? 'directory' : 'document', (value.id === this.directoryId));
            ids.push(obj);
        });
        return (ids.length > 0) ? ids : null;
    }

    loadAll() {
        let tmpData: DocumentSpecialRowDto[] = [];
        if (this.nodeType === 'DAMAGED') {
            this.spinner.show();
            if (this.infectedMode) {
                this.documentService.getRowsInfectedDocumentList().subscribe(
                    (res: HttpResponse<DocumentSpecialRowDto[]>) => {
                        // this.dataSource.data = res.body;
                        const documents: DocumentSpecialRowDto[] = this.prepareData(res.body);
                        tmpData = documents;
                        this.dataSource.data = tmpData;
                    },
                    (res: HttpErrorResponse) => this.onError(res.message)
                );

                this.spinner.hide();
            } else {
                this.spinner.show();
                this.documentService.getRowsDamagedDocumentList().subscribe(
                    (res: HttpResponse<DocumentSpecialRowDto[]>) => {
                        // this.dataSource.data = res.body;
                        const documents: DocumentSpecialRowDto[] = this.prepareData(res.body);
                        tmpData = documents;
                        this.dataSource.data = tmpData;
                        this.spinner.hide();
                    },
                    (res: HttpErrorResponse) => {
                        this.spinner.hide();
                        this.onError(res.message);
                    }
                );
            }
        }
        if (this.nodeType === 'TRASH') {
            this.spinner.show();
            this.directoryDocumentService.getRowsDeletedDocumentDirectoryList().subscribe(
                (res: HttpResponse<DocumentSpecialRowDto[]>) => {
                    // this.dataSource.data = res.body;
                    const documents: DocumentSpecialRowDto[] = this.prepareData(res.body);
                    tmpData = documents;
                    this.dataSource.data = tmpData;
                    this.spinner.hide();
                },
                (res: HttpErrorResponse) => {
                    this.spinner.hide();
                    this.onError(res.message);
                }
            );
        }
    }

    prepareData(data: DocumentSpecialRowDto[]): DocumentSpecialRowDto[] {
        const result: DocumentSpecialRowDto[] = [];
        data.forEach((value) => {
            result.push(value);
        });
        return result;
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }

    downloadFiles(downloadType: string) {
        this.spinner.show();
        const downloadObjectFromSelection = this.getDownloadObjectFromSelection();

        this.uploadDownloadService.downloadZip(downloadObjectFromSelection, downloadType).subscribe((res) => {
            const fileName = getFileNameFromResponseContentDispositionBase64(res);
            saveFile(res.body, fileName);
            this.spinner.hide();
            this.handlePdfPassword(res, downloadObjectFromSelection);
        }, () => this.spinner.hide());
    }

    private handlePdfPassword(res, downloadObjectFromSelection) {
        if (res.headers.keys().includes('x-pdf-protection')) {
            this.dialog.open(PdfPasswordProtectionDialog, {
                width: '350px',
                data: {
                    pdfPassword: res.headers.get('x-pdf-protection'),
                    archiveMode: downloadObjectFromSelection.length > 1
                }
            });
        }
    }

    getColor(row) {
        return (row.id === this.directoryId) ? 'var(--prime-color)' : 'black';
    }

    async deleteFiles() {
        const selectedFiles: DownloadObjectDto[] = this.getDownloadObjectFromSelection();
        const doDeleteFiles = async() => {
            let progressDialog: MatDialogRef<GenericProgressDialog, any>;
            if (selectedFiles.length > 5) {
                progressDialog = this.dialog.open(GenericProgressDialog, {
                    data: {
                        'total': selectedFiles.length,
                        'current': 0
                    }
                });
            }

            let processedFiles = 0;
            for (const file of selectedFiles) {
                await this.uploadDownloadService.deleteFiles([file]);
                processedFiles++;
                if (progressDialog) {
                    progressDialog.componentInstance.data.current = processedFiles;
                }
            }

            progressDialog?.close();
            this.loadAll();
        };

        const dialogData = (<ConfirmationDialogData>{
            title: this.translate.instant('global.dialog.areyousure'),
            text: this.translate.instant('vdrApp.document.bulkDeleteFilesText', {count: selectedFiles.length})
        });

        this.confirmation.confirmPromise(dialogData)
            .then(() => doDeleteFiles());
    }

    getIcon(type: string): string {
        // return 'fa ' + getIconForExtension(type) + '-o';
        return 'fa ' + getIconForExtension(type);
    }

    @Input()
    set directoryId(directoryId: number) {
        this._directoryId = directoryId;
        this.documentCriteriaDto.directoryId = directoryId;
    }

    get directoryId(): number {
        return this._directoryId;
    }

    hasFilters() {
        return false;
    }

    uploadStorageTask() {
        const ids: number[] = [];
        this.selection.selected.forEach((value) => {
            ids.push(value.id);
        });
        this.documentService.taskReUploadDocument(ids)
            .subscribe((result) => {
                this.jhiAlertService.success('document.retryInfo');
                this.selection.clear();
                //     this.loadAll();
            });
    }

    synchronizeList() {
        this.loadAll();
    }


    uploadStorageAvailable(): boolean {
        const directoryDocumentRowDtos = this.selection.selected;
        return directoryDocumentRowDtos.length > 0 && directoryDocumentRowDtos
            .every((row) => row.status === 'LOADING' || row.status === 'AFTER_DOC_UPLOAD');
    }

    restoreAction() {
        this.spinner.show();
        this.directoryDocumentService.restore(this.selection.selected).subscribe((result) => {
            this.selection.clear();
            this.loadAll();
            this.spinner.hide();
        }, (error1) => {
            this.spinner.hide();
        });
    }

    restoreActionAvailable(): boolean {
        const directoryDocumentRowDtos = this.selection.selected;
        return directoryDocumentRowDtos.length > 0;
    }

    async deletePermanentlyAction() {
        const selectedFiles: DocumentSpecialRowDto[] = this.selection.selected;
        let progressDialog: MatDialogRef<GenericProgressDialog, any>;
        if (selectedFiles.length > 3) {
            progressDialog = this.dialog.open(GenericProgressDialog, {
                width: '450px',
                height: '70px',
                data: {
                    'total': selectedFiles.length,
                    'current': 0
                }
            });
        }

        let processedFiles = 0;
        for (const file of selectedFiles) {
            try {
                await this.directoryDocumentService.deletePermanently([file]).toPromise();
            } catch (e) {
                this.jhiAlertService.error('Nie udalo sie usunac pliku');
            } finally {
                processedFiles++;
            }
            if (progressDialog) {
                progressDialog.componentInstance.data.current = processedFiles;
            }
        }

        progressDialog?.close();
        this.selection.clear();
        this.loadAll();

        // this.spinner.show();
        // this.directoryDocumentService.deletePermanently(this.selection.selected).subscribe((result) => {
        //     this.selection.clear();
        //     this.loadAll();
        //     this.spinner.hide();
        // }, (error1) => {
        //     this.spinner.hide();
        // });
    }

    deletePermanentlyActionAvailable(): boolean {
        const directoryDocumentRowDtos = this.selection.selected;
        return directoryDocumentRowDtos.length > 0;
    }

    deletePermanentlyDialog(): void {
        const dialogRef = this.dialog.open(PermanentDeleteDialog, {
            width: '450px',
            data: {count: this.selection.selected.length},
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(() => {
            if (dialogRef.componentInstance.result === true) {
                this.deletePermanentlyAction();
            }
        });
    }

    infectedModeOn() {
        this.infectedMode = true;
        this.selection.clear();
        this.loadAll();
    }

    infectedModeOff() {
        this.infectedMode = false;
        this.selection.clear();
        this.loadAll();
    }

    deleteInfectedAction() {
        this.documentService.deleteInfected(this.selection.selected).subscribe((result) => {
            this.selection.clear();
            this.loadAll();
        });
    }

    deleteInfectedAvailable(): boolean {
        const directoryDocumentRowDtos = this.selection.selected;
        return directoryDocumentRowDtos.length > 0 && directoryDocumentRowDtos
            .every((row) => row.status === 'INFECTED');
    }

    showDocumentEventHistory(documentId: number): void {
        const dialogRef = this.dialog.open(DocumentEventHistoryDialog, {
            width: '840px',
            data: documentId,
            disableClose: true,
            hasBackdrop: true
        });
    }

    attacheDocumentEventHistory(): boolean {
        return this.nodeType != 'directory' && this.principal.hasAnyAuthorityDirect(['SUPER_ADMIN', 'ADMIN']);
    }
}
