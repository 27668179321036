import {Component, ElementRef, OnInit} from '@angular/core';
import {JhiDataUtils, JhiEventManager, JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {Principal, AccountService, JhiLanguageHelper} from '../../shared';
import {ProjectVdr, ProjectVdrService} from '@admin/project-vdr';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'jhi-project2',
    templateUrl: './project2.component.html',
    styleUrls: ['./project2.scss'],
})
export class Project2Component implements OnInit {
    error: string;
    success: string;
    settingsAccount: any;
    languages: any[];
    isGeneralSettings: boolean;
    project: ProjectVdr;
    isSaving: boolean;
    colorPresets = [];
    disableColorInput = true;
    color2: any = {
        r: 100, g: 130, b: 150
    };
    fileInfo: string;
    fileUploadError: string;
    opacityOptions = [];
    constructor(
        private account: AccountService,
        private principal: Principal,
        private languageService: JhiLanguageService,
        private languageHelper: JhiLanguageHelper,
        private projectService: ProjectVdrService,
        private eventManager: JhiEventManager,
        private dataUtils: JhiDataUtils,
        private elementRef: ElementRef,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.isGeneralSettings = true;
        this.opacityOptions = [{value:0.4, label: this.translateService.instant('vdrApp.project.watermark.opacityLevel.high')},
        {value:0.5, label: this.translateService.instant('vdrApp.project.watermark.opacityLevel.mid')},
        {value:0.6, label: this.translateService.instant('vdrApp.project.watermark.opacityLevel.low')}
        ];
        this.colorPresets = [
            '#e60000',
            '#ff6633',
            '#ff9933',
            '#ffff66',
            '#b3ff66',
            '#80ffbf',
            '#80dfff',
            '#00ace6',
            '#1a53ff',
            '#b366ff',
            '#9933ff',
            '#600080',
            '#800060',
            '#ff4dd2',
            '#e600ac',
            '#e60073',
            '#99004d',
            '#660000',
            '#000000',
            '#558000',
            '#24478f',
            '#ff9966',
            '#b3ffda',
            '#ffff4d',
            '#33cc33',
            '#ffcccc',
            '#ff3333',
            '#2eb82e',
            '#669999',
            '#999999'
        ];

        this.principal.identity(false).then((userIdentity) => {
            const id = userIdentity.project.id;
            this.projectService.find(id)
                .subscribe((projectResponse: HttpResponse<ProjectVdr>) => {
                    this.project = Object.assign(new ProjectVdr(), projectResponse.body);
                    if (!this.project.mainColor) {
                        this.project.mainColor = '#1976D2';
                    }
                });
            this.getFileInfo(id);
        });


    }

    selectGeneralSettings() {
        this.isGeneralSettings = true;
    }

    byteSize(field) {
        return this.dataUtils.byteSize(field);
    }

    openFile(contentType, field) {
        return this.dataUtils.openFile(contentType, field);
    }

    setFileData(event, entity, field, isImage) {
        this.fileUploadError = null;
        const maxFileSize = 256;
        if (event.target.files[0].size > (maxFileSize * 1024)) {
            this.fileUploadError = 'Wybrany plik za duży ' + Math.floor(event.target.files[0].size / 1024) + ' kB. Maksymalny rozmiar to ' + maxFileSize + ' kB';
            return false;
        }
        this.dataUtils.setFileData(event, entity, field, isImage);
    }

    clearInputImage(field: string, fieldContentType: string, idInput: string) {
        this.dataUtils.clearInputImage(this.project, this.elementRef, field, fieldContentType, idInput);
    }

    selectThemeSettings() {
        this.isGeneralSettings = false;
    }

    save() {
        this.isSaving = true;
        if (this.project.id !== undefined) {
            this.subscribeToSaveResponse(
                this.projectService.update(this.project));
        } else {
            this.subscribeToSaveResponse(
                this.projectService.create(this.project));
        }

        document.body.style.setProperty('--prime-color', this.project.mainColor);
    }

    changeAutomaticIndexing(check: boolean) {
        this.project.automaticIndexing = check;
    }

    changeshowCompanyName(check: boolean) {
        this.project.showCompanyName = check;
    }

    changeTherm(therm: string) {
        this.project.therm = therm;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ProjectVdr>>) {
        result.subscribe((res: HttpResponse<ProjectVdr>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(result: ProjectVdr) {
        this.eventManager.broadcast({name: 'projectListModification', content: 'OK'});
        this.isSaving = false;
    }

    private onSaveError() {
        this.isSaving = false;
    }

    changeTwoPhaseAuthentication(b: boolean) {
        this.project.twoPhaseAuthentication = b;
    }

    changeVersioning(b: boolean) {
        this.project.versioning = b;
    }

    getFileInfo(id: number) {
        this.projectService.getIconInfo(id).subscribe((response) => {
            this.fileInfo = (response.body.info === 'no.error' || response.body.info === 'error.reading.file'
                ? this.translateService.instant('project.file.' + response.body.info)
                : response.body.info);
        });
    }

    changeQuestionLimit(check: boolean) {
        this.project.questionLimit = check;
    }
}
